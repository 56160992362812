import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { makeStyles } from '@material-ui/core/styles';
// import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from '../components/ContactFormWrapper'

const ServiceTemplate = ({ data: { previous, next, post } }) => {
  const classes = useStyles();

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <section className="full-width">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          {!!post.content && (
            <section itemProp="articleBody" className={classes.description}>{parse(post.content)}</section>
          )}
        </article>
        <div className={classes.clearfix} />
        {/* <div className="alignwide">
          <h3>Other Case Studies</h3>           
          <div>
            {previous && (
              <div className={classes.lineCard}>
                <Link to={`/portfolio/${previous.slug}`} rel="prev" className={`${classes.navLink} move-child-icon-to-right`}>
                  <h3 className={classes.lineCardTitle}>{parse(previous.title)}</h3>
                  <span className={classes.lineCardDesc}>{parse(previous.excerpt)}</span>
                  <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
                </Link>
            </div>
            )}
            {next && (
              <div className={classes.lineCard}>
                <Link to={`/portfolio/${next.slug}`} rel="next" className={`${classes.navLink} move-child-icon-to-right`}>
                  <h3 className={classes.lineCardTitle}>{parse(next.title)}</h3>
                  <span className={classes.lineCardDesc}>{parse(next.excerpt)}</span>
                  <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
                </Link>
              </div>
            )}
          </div>
        </div> */}

        <br />
      </section>
      <ContactForm linkTo='portfolio' />
    </Layout>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      slug
      title
      excerpt
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      slug
      title
      excerpt
    }
  }
`

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: 18
  },
  postDate: {
    color: '#757575',
    fontSize: 14
  },
  bio: {
    display: 'flex',
    borderTop: '1px solid rgba(230, 230, 230, 1)',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    marginTop: 25,
    padding: '25px 0'
  },
  avatar: {
    borderRadius: '50%',
    marginRight: 24
  },
  writtenBy: {
    textTransform: 'uppercase',
    display: 'block',
    color: '#6f6f6f',
    fontWeight: 400
  },
  writerName: {
    fontSize: 22,
    fontWeight: 500,
    margin: '5px 0 10px 0'
  },
  navLink: {
    fontSize: 18,
    textDecoration: 'none'
  },
  lineCard: {
    border: '1px solid #DFDFDF',
    width: '100%',
    display: 'flex',
    marginBottom: 12,
    padding: '16px 24px 2px 24px'
  },
  lineCardTitle: {
    color: '#161616',
    marginTop: 0
  },
  lineCardDesc: {
    color: '#6f6f6f',
    fontSize: 16
  },
  whiteIcon: {
    fill: '#161616',
    fontSize: '2.5rem',
    position: 'relative'
  },
  clearfix: {
    clear: 'both'
  }
}));